import { Injectable } from '@angular/core';
import { LayoutStore } from './layout.store';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  constructor(private layoutStore: LayoutStore) {}

  toggleSidebar() {
    this.layoutStore.update(({ compactMode, ...rest }) => ({ ...rest, compactMode: !compactMode }));
  }

  setViewMode(viewOnly: boolean) {
    this.layoutStore.update({ viewMode: viewOnly });
  }
}
